/* eslint-disable max-lines-per-function, array-callback-return, camelcase, new-cap */
import $ from 'jquery';
import Tooltip from 'tooltip.js';

import { getDomElement, getDomElements, makeHtml } from '../utils/dom';

const chatSVG = className => `
  <svg class="tiger-popper-chat-icon  ${
    className || ``
  }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.98 31.91" height="27" width="27">
    <path class="intercom-icon" d="M26,32,18.1,28.9H3.19A3.3,3.3,0,0,1,0,25.51V5.65A3.3,3.3,0,0,1,3.19,2.26H22.83A3.3,3.3,0,0,1,26,5.65v17.1h0ZM22.18,20.86A.83.83,0,0,0,21,20.77a12.45,12.45,0,0,1-8,2.49,12.56,12.56,0,0,1-8-2.5.82.82,0,0,0-1.19.1.94.94,0,0,0-.21.66,1,1,0,0,0,.3.62A13.93,13.93,0,0,0,13,25.07a13.93,13.93,0,0,0,9.07-2.93,1,1,0,0,0,.3-.62A1,1,0,0,0,22.18,20.86Z" transform="translate(0 -0.09)"></path>
    <circle class="tiger-popper-notification js-set-chat-notification" cx="20.81" cy="7.07" r="7.17" fill="#db5050"></circle>
    </svg>
    `;

const helpLinks = `
    <div class="modal-links-container" >
      <a class="modal-links modal-link-one" data-t="help-modal-user-guide" target="_blank" rel="noopener" href="https://intercom.help/pagetiger">
        <svg class="modal-links-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 60">
          <path d="M38.91,0H6.5V60h47V0ZM17.5,14h10a1,1,0,0,1,0,2h-10a1,1,0,0,1,0-2Zm25,34h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Z" transform="translate(-6.5)"></path>
        </svg>

        <p class="modal-links-heading">User Guide</p>
      </a>

      <a class="modal-links modal-link-one js-activate-chat" data-micromodal-close data-t="help-modal-contact-us">
        ${chatSVG(`modal-links-svg`)}
        <p class="modal-links-heading">Contact Us</p>
      </a>

      <a class="modal-links modal-link-one"
        href="${window.tigerlabsUrl}"
        rel="noopener"
        target="_blank"
        data-t="help-modal-tiger-labs">
        <svg
          class="modal-links-svg"
          viewBox="0 0 415.02 512"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M454.42,407.68,326,179.38V58.29A30,30,0,0,0,316,0H196a30,30,0,0,0-10,58.29V179.38L57.58,407.68a70,70,0,0,0,61,104.32H393.41a70,70,0,0,0,61-104.32ZM216,292a30,30,0,1,1,30-30A30,30,0,0,1,216,292Zm40-100a10,10,0,1,1,10-10A10,10,0,0,1,256,192Zm40,60a10,10,0,1,1,10-10A10,10,0,0,1,296,252Z"
            transform="translate(-48.49)"
          />
        </svg>
        <p class="modal-links-heading">TigerLabs</p>
      </a>

      <a class="modal-links modal-link-one" data-t="help-modal-email-support" href="mailto:support@pagetiger.com">
        <svg class="modal-links-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10">
          <path d="M7,9,5.27,7.48.32,11.73A1,1,0,0,0,1,12H13a1,1,0,0,0,.69-.27L8.73,7.48Z" transform="translate(0 -2)"></path>
          <path d="M13.68,2.27A1,1,0,0,0,13,2H1a1,1,0,0,0-.69.27L7,8Z" transform="translate(0 -2)"></path>
          <polygon points="0 0.88 0 9.19 4.83 5.08 0 0.88" />
          <polygon points="9.17 5.08 14 9.19 14 0.88 9.17 5.08" />
        </svg>
        <p class="modal-links-heading">Email Support</p>
      </a>
    </div>`;

const registerToolTips = () => {
  // eslint-disable-next-line array-callback-return
  getDomElements('[data-toggle="tooltip"]').map(el => {
    // eslint-disable-next-line no-new
    new Tooltip(el, {
      arrowSelector: 'tooltip-arrow',
      container: 'body',
      placement: 'right',
      title: el.getAttribute('data-title') ?? ''
    });
  });
};

const getIntercomUnreadMessages = () => {
  if (window.Intercom) {
    const originalPageTitle = document.title;

    // As soon as the window is active the unread count sets itself to zero
    window.Intercom('onUnreadCountChange', unreadCount => {
      getDomElements('.js-set-chat-notification').map(el => {
        if (unreadCount >= 1) {
          // Cannot use the `classList` api as IE11 doesn't support it on SVG elements 🤦‍♂️
          el.setAttribute('class', `${el.getAttribute('class')} mod-show`);
          document.title = `(${unreadCount}) ${originalPageTitle}`;
        } else {
          const updatedClassName = el.getAttribute('class').replace(/mod-show/u, '');

          el.setAttribute('class', updatedClassName);
          document.title = originalPageTitle;
        }
      });
    });
  }
};

// Force removal of the video from the DOM (this stops it playing)  when the modal is closed.
const removeVideoContainer = () => {
  getDomElement('.js-remove-on-modal-close').map(el => {
    // eslint-disable-next-line no-param-reassign
    el.parentNode.innerHTML = '';
  });
};

$(document).on('click', '.modal-close-button', removeVideoContainer);

$(document).on('keyup', e => {
  if (e.originalEvent.keyCode === 27) {
    removeVideoContainer();
  }
});

const setupHelp = () => {
  const $$ = document.querySelector.bind(document);
  const hideHelp = $$('#hdnHideHelp');

  if (hideHelp && hideHelp.value === 'true') {
    // Help is hidden - Don't do setup
    return;
  }

  renderTigerPopper();
  registerToolTips();

  const modal = Object.create(window.Modal);

  getDomElement('body').map(body => {
    body.addEventListener('click', ({ target }) => {
      if (target.classList.contains('js-activate-help')) {
        // eslint-disable-next-line no-unused-expressions
        window.Intercom && window.Intercom('hide');
        const isPlayVideoButton = target.classList.contains('mod-button-icon');
        const shouldAutoPlay = isPlayVideoButton || window.autoPlayVideo;

        modal.init({
          body: makeHtml(`
            <div class="modal-help">
            ${
              window.videoUrl
                ? `<div class="modal-video-container">
                <video class="modal-video-element js-remove-on-modal-close" controls controlslist="nodownload" ${
                  shouldAutoPlay && `autoplay`
                }>
                  <source src="${window.videoUrl}" type="video/mp4">
                </video>
              </div>`
                : ``
            }
              ${isPlayVideoButton ? '' : helpLinks}
            </div>`),
          footer: makeHtml(
            '<button class="tertiary-link-style-button" data-t="help-modal-footer-close" data-micromodal-close type="button">Close</button>'
          ),
          isLarge: true,
          modalType: 'help',
          title: 'Page Tiger Help'
        });
      }
    });
  });

  if (window.autoPlayVideo) {
    getDomElement('.js-activate-help').map(el => el.click());
  }

  getIntercomUnreadMessages();
};

const renderTigerPopper = () => {
  document.body.appendChild(
    makeHtml(`
    <div class="tiger-popper">
      ${
        window.videoUrl
          ? `<button class="tiger-popper-button mod-button-icon js-activate-help" data-t="video-help-popper-button" type="button" data-toggle="tooltip" data-placement="right" data-title="Video Help">
              <span class="tiger-popper-hide">Contact Us</span>
                <svg class="tiger-popper-play-icon mod-rotate" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="27" height="24" viewBox="0 0 373.008 373.008">
                  <path d="M61.792,2.588C64.771,0.864,68.105,0,71.444,0c3.33,0,6.663,0.864,9.655,2.588l230.116,167.2
                  c5.963,3.445,9.656,9.823,9.656,16.719c0,6.895-3.683,13.272-9.656,16.713L81.099,370.427c-5.972,3.441-13.334,3.441-19.302,0
                  c-5.973-3.453-9.66-9.833-9.66-16.724V19.305C52.137,12.413,55.818,6.036,61.792,2.588z" fill="#fff"/>
                </svg>
            </button>`
          : ``
      }
    ${
      window.helpUrl
        ? `<a class="tiger-popper-button mod-button-icon help-button" data-t="view-documentation-popper-button" href="${window.helpUrl}" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" data-placement="right" data-title="View Documentation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 60" height="25" width="27" class="tiger-popper-doc-icon mod-rotate">
                  <path d="M38.91,0H6.5V60h47V0ZM17.5,14h10a1,1,0,0,1,0,2h-10a1,1,0,0,1,0-2Zm25,34h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Z" transform="translate(-6.5)" fill="#fff"></path>
                </svg>
              </a>`
        : ``
    }
    ${
      window.Intercom
        ? `<button class="tiger-popper-button mod-button-icon js-activate-chat" data-t="contact-us-popper-button" type="button" data-toggle="tooltip" data-placement="right" data-title="Contact Us">
        ${chatSVG(`mod-rotate`)}
        <span class="tiger-popper-hide">Contact Us</span>
      </button>`
        : ``
    }
      <button class="tiger-popper-button js-activate-help" data-t="help-popper-button" type="button" data-toggle="tooltip" data-placement="right" data-title="Help Centre">Help</button>
    </div>
  `)
  );
};

export { setupHelp };
